import { useEffect, useState } from 'react'
import type { FC } from 'react'
import { useQueryClient } from 'react-query'
import { Box, Grid, Button, Divider, TextField, CardContent, Typography } from '@mui/material'
import EditField from '../../Generic/EditField'
import { useFormik } from 'formik'
import { InputAdornment } from '@mui/material'
import { styled } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import Card from '@mui/material/Card'
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input'
import { Steerage, SteerageProvider, SteerageProviderDataSource, States } from './types'
import {
  listSteerageKey,
  listSteerageSteerageProvidersKey,
  useAddProviderToSteerage,
  useEditSteerageSteerageProvider,
} from '~/api/SteerageService'
import { formatPhoneNumber } from '~/utils'

interface SteerageProps {
  steerage: Steerage
  steerageProviderCreateForm: boolean
  steerageProvider: SteerageProvider | null
  onCancel: (event: any) => void
  reasonForExceptionList: string[][] | undefined
  states: States[]
}

const MuiTelInputStyled = styled(MuiTelInput)`
  & .MuiTelInput-IconButton + p {
    display: none;
  }
  & .MuiTelInput-IconButton {
    display: none;
  }
`

const SteerageProviderForm: FC<SteerageProps> = props => {
  const states = props?.states
  const client = useQueryClient()

  const [stateSearchString, setStateSearchString] = useState<string>('')
  const [selectedState, setSelectedState] = useState<States | null>(
    props?.steerageProvider?.state ?? null
  )
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  const { mutateAsync: handleEditSteerageProvider } = useEditSteerageSteerageProvider()
  const { mutateAsync: handleAddProviderToSteerage } = useAddProviderToSteerage()

  const setSearchTermsForState = (event, newValue: States | null) => {
    setSelectedState(newValue)
  }
  // It contains formatted phone with country code
  const [phone, setPhone] = useState<string | undefined>(
    props?.steerageProviderCreateForm
      ? ''
      : formatPhoneNumber(props?.steerageProvider?.phone?.toString())
  )
  // Contains phone number value
  const [phoneValue, setPhoneValue] = useState<string | null>(
    props?.steerageProvider?.phone?.toString() ?? null
  )

  // It contains formatted fax with country code
  const [formattedFax, setFormattedFax] = useState<string | undefined>(
    props?.steerageProviderCreateForm
      ? ''
      : formatPhoneNumber(props?.steerageProvider?.fax?.toString())
  )
  // contains fax number value
  const [faxValue, setFaxValue] = useState<string | null>(
    props?.steerageProvider?.fax?.toString() ?? null
  )

  const isInputDirty = (name: string) => {
    let boolean = formik.getFieldMeta(name).value === formik.getFieldMeta(name).initialValue
    return !boolean
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: props?.steerageProviderCreateForm ? '' : props?.steerageProvider?.firstName,
      lastName: props?.steerageProviderCreateForm ? '' : props?.steerageProvider?.lastName,
      npi: props?.steerageProviderCreateForm ? '' : props?.steerageProvider?.npi,
      specialtyList: props?.steerageProviderCreateForm
        ? ''
        : props?.steerageProvider?.specialtyList?.join(', '),
      practiceName: props?.steerageProviderCreateForm
        ? ''
        : props?.steerageProvider?.careOrganizationName,
      addressLine1: props?.steerageProviderCreateForm ? '' : props?.steerageProvider?.addressLine1,
      addressLine2: props?.steerageProviderCreateForm ? '' : props?.steerageProvider?.addressLine2,
      city: props?.steerageProviderCreateForm ? '' : props?.steerageProvider?.city,
      zipCode: props?.steerageProviderCreateForm ? '' : props?.steerageProvider?.zipCode,
      reasonForException: props?.steerageProviderCreateForm
        ? ''
        : props?.steerageProvider?.reasonForException,
    },
    onSubmit: values => {
      const payload = {
        firstName: values['firstName']?.trim() || null,
        lastName: values['lastName']?.trim() || null,
        npi: values['npi'] || null,
        specialtyList: values['specialtyList'] == '' ? [] : values['specialtyList']?.split(/[,]+/),
        careOrganizationName: values['practiceName']?.trim() ?? null,
        addressLine1: values['addressLine1'] || null,
        addressLine2: values['addressLine2'] || null,
        city: values['city'] || null,
        state_id: selectedState?.id || null,
        zipCode: values['zipCode'] || null,
        phone: phoneValue || null,
        fax: faxValue || null,
        reasonForException: values['reasonForException'] ?? '',
        is_manually_edited: true,
      }

      if (props?.steerageProviderCreateForm) {
        payload['recommendationStatus'] = 'NEUTRAL'
        payload['dataSource'] = SteerageProviderDataSource.Manual
        payload['steerageId'] = props?.steerage?.id
        // calling the create api for adding provider to the steerage
        handleAddProviderToSteerage(payload).then(async () => {
          formik.resetForm()
          setSelectedState(null)
          // hide the form after saving the provider
          props?.onCancel(values)

          // invalidate queries for refetching the providers
          await client.cancelQueries([listSteerageKey, props?.steerage?.id])
          await client.invalidateQueries([listSteerageKey, props?.steerage?.id])
          await client.cancelQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
          await client.invalidateQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
        })
      } else {
        // If the phone or fax number value is changed then
        // mark the contact details as not verified
        if (
          phoneValue != props?.steerageProvider?.phone ||
          faxValue != props?.steerageProvider?.fax
        ) {
          payload['hasVerifiedContactDetails'] = false
        }

        if (isInputDirty('npi') || isInputDirty('practiceName') || isInputDirty('zipCode')) {
          payload['networkPartnerAgreementType'] = null
        }
        // calling the update provider api
        payload['steerageId'] = props?.steerage?.id
        payload['providerId'] = props.steerageProvider?.id
        handleEditSteerageProvider(payload).then(async () => {
          // hide the form after saving the provider
          props?.onCancel(values)
          // invalidate queries for refetching the providers
          await client.cancelQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
          await client.invalidateQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
        })
      }
    },
  })

  useEffect(() => {
    // for formatting the fax number and handling the value change
    // The formatting of the Fax won't happen in the mui-tel package
    // until we add country code to the Fax number
    // as that is how it find out, which country formatting need to be done
    if (!formattedFax) {
      setFormattedFax('+1 ')
    }

    if (formattedFax && !formattedFax.includes('+1')) {
      setFormattedFax('+1 ' + formattedFax)
    }
  }, [formattedFax])

  useEffect(() => {
    // for formatting the phone number and handling the value change
    // The formatting of the phone won't happen in the mui-tel package
    // until we add country code to the phone number
    // as that is how it find out, which country formatting need to be done
    if (!phone) {
      setPhone('+1 ')
    }

    if (phone && !phone.includes('+1')) {
      setPhone('+1 ' + phone)
    }
  }, [phone])

  useEffect(() => {
    validateForm()
  }, [
    formik.values.firstName,
    formik.values.lastName,
    formik.values.npi,
    formik.values.practiceName,
    formik.values.reasonForException,
  ])

  const validateForm = () => {
    // Don't validate if provider is added via search
    if (props?.steerageProvider?.dataSource == SteerageProviderDataSource.Ribbon) {
      setIsFormValid(true)
      return
    }

    // validate the provider form
    // Reason for exception is required
    // Either FirstName, LastName and NPI is required or PracticeName is required
    if (!formik.values.reasonForException && props?.steerageProviderCreateForm) {
      setIsFormValid(false)
      return
    }
    if (
      (formik.values.firstName && formik.values.lastName && formik.values.npi) ||
      formik.values.practiceName
    ) {
      setIsFormValid(true)
      return
    }
    setIsFormValid(false)
  }

  const getFormTitle = (steerage: Steerage) => {
    // We need to show the type of steerage
    if (steerage.isEffectivelyReferralAndWaiver) return 'Care Pass Only'
    if (steerage.isEffectivelyReferralOnly) return 'Referral Only'
    if (steerage.isEffectivelyWaiverOnly) return 'Care Pass Only'
    return ''
  }

  return (
    <Box>
      <Card>
        <CardContent>
          <Box>
            {props.steerageProviderCreateForm ? (
              <Typography style={{ fontWeight: 'bold' }}>Add Provider</Typography>
            ) : (
              <Typography style={{ fontWeight: 'bold' }}>
                Edit Provider({getFormTitle(props?.steerage)})
              </Typography>
            )}
          </Box>
          <br />
          <Grid container spacing={6}>
            <EditField
              InputLabelProps={{ shrink: true }}
              label="First Name"
              type="input"
              name="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Last Name"
              type="input"
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
          </Grid>
          <Grid container spacing={6}>
            <EditField
              InputLabelProps={{ shrink: true }}
              label="NPI"
              type="number"
              name="npi"
              onChange={formik.handleChange}
              value={formik.values.npi}
              disabled={
                !props?.steerageProviderCreateForm &&
                props?.steerageProvider?.sourceIdentifier != null
              }
            />
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Specialty"
              type="input"
              name="specialtyList"
              onChange={formik.handleChange}
              value={formik.values.specialtyList}
            />
          </Grid>
          <Grid container spacing={6}>
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Practice Name"
              type="input"
              name="practiceName"
              onChange={formik.handleChange}
              value={formik.values.practiceName}
            />
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Address Line 1"
              type="input"
              name="addressLine1"
              onChange={formik.handleChange}
              value={formik.values.addressLine1}
            />
          </Grid>

          <Grid container spacing={6}>
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Address Line 2"
              type="input"
              name="addressLine2"
              onChange={formik.handleChange}
              value={formik.values.addressLine2}
            />
            <EditField
              InputLabelProps={{ shrink: true }}
              label="City"
              type="input"
              name="city"
              onChange={formik.handleChange}
              value={formik.values.city}
            />
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Autocomplete
                value={selectedState}
                onChange={setSearchTermsForState}
                inputValue={stateSearchString}
                onInputChange={(event, newSearchString) => {
                  setStateSearchString(newSearchString)
                }}
                id="state"
                autoComplete={true}
                size="small"
                forcePopupIcon={false}
                options={states || []}
                getOptionLabel={option => `${option.abbreviation} - ${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, state: States) => (
                  <li {...props} key={state.id}>
                    {state.abbreviation}
                    {' - '}
                    {state.name}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="State"
                    variant="standard"
                    InputProps={{
                      ...params?.InputProps,
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Zip Code"
              type="input"
              name="zipCode"
              onChange={formik.handleChange}
              value={formik.values.zipCode}
              inputProps={{ maxLength: 5 }}
            />
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <MuiTelInputStyled
                name="phone"
                label="Phone"
                size="small"
                variant="standard"
                value={phone}
                onChange={(value: string, info: MuiTelInputInfo) => {
                  setPhone(value)
                  setPhoneValue(info.nationalNumber ? info.nationalNumber : '')
                }}
                disableDropdown
                onlyCountries={['US']}
                fullWidth
                forceCallingCode
              />
            </Grid>
            <Grid item xs={6}>
              <MuiTelInputStyled
                name="fax"
                label="Fax"
                size="small"
                variant="standard"
                value={formattedFax}
                onChange={(value: string, info: MuiTelInputInfo) => {
                  setFormattedFax(value)
                  setFaxValue(info.nationalNumber ? info.nationalNumber : '')
                }}
                disableDropdown
                onlyCountries={['US']}
                fullWidth
                forceCallingCode
              />
            </Grid>
          </Grid>
          {props?.steerageProviderCreateForm ? (
            <Grid container spacing={6}>
              <EditField
                label="Reason for Exception"
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: true }}
                onChange={formik.handleChange}
                value={formik.values.reasonForException}
                name="reasonForException"
              >
                <option value="">None</option>
                {props?.reasonForExceptionList &&
                  props?.reasonForExceptionList?.length > 0 &&
                  props?.reasonForExceptionList?.map(reasonForException => (
                    <option key={reasonForException[0]} value={reasonForException[0]}>
                      {reasonForException[1]}
                    </option>
                  ))}
              </EditField>
            </Grid>
          ) : (
            <></>
          )}
          <Grid container spacing={4} mt={4}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 'medium', fontSize: '14px' }}>
                *Phone/Fax number update will reflect in the provider directory
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex' }} justifyContent="flex-end">
              {props.steerageProviderCreateForm ? (
                <Box>
                  <Button onClick={props?.onCancel} color="primary" style={{ fontSize: '14px' }}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => formik.handleSubmit()}
                    color="primary"
                    variant="contained"
                    disabled={!isFormValid}
                    style={{ fontSize: '14px', borderRadius: '28px' }}
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <>
                  <Button onClick={props?.onCancel} color="primary" style={{ fontSize: '14px' }}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => formik.handleSubmit()}
                    color="primary"
                    variant="contained"
                    disabled={!isFormValid}
                    style={{ fontSize: '14px', borderRadius: '28px' }}
                  >
                    Save
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Divider />
      <br />
    </Box>
  )
}

export default SteerageProviderForm
